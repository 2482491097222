// AppContent.js

import React, { useState, useEffect, useRef } from "react";
import ChatWindow from "./components/ChatWindow";
//import ChatSidebar from "./components/ChatSidebar";
//import ChatSidebar from "./components/ChatSidebar";
import ChatHeader from "./components/ChatHeader";
import AgeConfirmation from './components/AgeConfirmation';
import LimitReached from './components/LimitReached';
import "./App.css";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

const baseUrl = process.env.REACT_APP_BASE_URL;

const AppContent = () => {
  const { isAuthenticated, loginWithRedirect, user, logout, getAccessTokenSilently } = useAuth0();
  const [chats, setChats] = useState([]);
  const [currentChatId, setCurrentChatId] = useState(0);
  const [userId, setUserId] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showAgeConfirmation, setShowAgeConfirmation] = useState(true);
  const [promptCount, setPromptCount] = useState(0); 
  const [limitReached, setLimitReached] = useState(false); 
  const [isNewChat, setIsNewChat] = useState(false);
  const isCreatingChat = useRef(false);

  // Access token retrieval
  const fetchAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      return token;
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  useEffect(() => {
    const isAgeConfirmed = localStorage.getItem('ageConfirmed') === 'true';
    setShowAgeConfirmation(!isAgeConfirmed);
  }, [showAgeConfirmation]); 

  useEffect(() => {
    const createUser = async () => {
      setLoading(true);
      try {
        const response = await axios.post(`${baseUrl}/api/create-user/`);
        if (response.data.success) {
          setUserId(response.data.user_id);  
          console.log("Temp user created");
        }
      } catch (error) {
        console.error("Error creating user:", error);
        setError("Failed to create a user. Please try again.");
      } finally {
        setLoading(false);
      }
    };
  
    const fetchChatHistory = async () => {
      setLoading(true);
      try {
        const token = await fetchAccessToken();
        const response = await axios.get(`${baseUrl}/api/chat-history/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.data.success) {
          const chatHistory = response.data.chat_history;
          console.log("HHIISTORT", chatHistory)
          setChats(chatHistory);
          if (chatHistory.length > 0) {
            setCurrentChatId(chatHistory[0].chat_id);
          } else {
            handleCreateNewChat();
          }
        }
      } catch (error) {
        console.error("Error fetching chat history:", error);
      } finally {
        setLoading(false);
      }
    };
  
    // Main logic in the combined useEffect
    if (!isAuthenticated) {
      createUser(); // If user is not authenticated, create a temporary user
      handleCreateNewChat();
    } else {
      fetchChatHistory(); // If authenticated, fetch chat history
    }
  }, [isAuthenticated]);

  const updateChatMessages = (chatId, newMessage, newTitle) => {
    setChats((prevChats) => {
        const updatedChats = prevChats.map((chat) => {
          if (chat.chat_id === chatId) {
            return {
                ...chat,
                title: newTitle || chat.title, 
                messages: [...chat.messages, newMessage],
            };
          }
          return chat;
        });
        return updatedChats;
    });
};

  const handleSendMessage = async (message) => {
    const newMessage = { role: "user", content: message };
    updateChatMessages(currentChatId, newMessage);
    setLoading(true);
    setError("");

    try {
        let response;

        // Check if the user is authenticated
        if (isAuthenticated) {
            const token = await fetchAccessToken();
            response = await axios.post(
                `${baseUrl}/api/chat/${currentChatId}/`,
                { message },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        } else {
            // Increment the prompt count when the user is not authenticated
            setPromptCount((prevCount) => prevCount + 1);
            // Handle non-authenticated user
            response = await axios.post(
                `${baseUrl}/api/chat/temp/${currentChatId}/`,
                {
                    message,
                    user_id: userId,  // Pass the temporary user ID if available
                }
            );
        }

        // Add bot response to chat
        const botMessage = { role: "assistant", content: response.data.response };
        updateChatMessages(currentChatId, botMessage, response.data.title);
        setLoading(false);
    } catch (error) {
        const botMessage = { role: "assistant", content: "Sorry, I couldn't find the answers. Please rephrase or try again!" };
        updateChatMessages(currentChatId, botMessage);
        console.error("Error sending message:", error);
        // setError("Failed to send message. Please try again.");
        setLoading(false);  // Stop loading in case of error
    }
};


useEffect(() => {
  if (!isAuthenticated && promptCount >= 5) {
      setLimitReached(true); 
  }
}, [promptCount, isAuthenticated]);

const handleLimitConfirm = () => {
  setLimitReached(false);
  loginWithRedirect();
};


  const handleSelectChat = (chatId) => {
    setCurrentChatId(chatId);
  };

  const handleAgeConfirm = () => {
    setShowAgeConfirmation(false);
    localStorage.setItem('ageConfirmed', 'true'); 
};


  const handleAgeCancel = () => {
    setShowAgeConfirmation(true); 
    localStorage.setItem('ageConfirmed', 'false'); 
    alert("You will be redirected, and the window will now close.");
    window.location.href = "https://www.google.com";
};

  const handleCreateNewChat = async () => {
    let response;
    if (isCreatingChat.current) return;
    isCreatingChat.current = true;
    setLoading(true);
    setError("");
    
    try {
      if (isAuthenticated) {
        const token = await fetchAccessToken();
         response = await axios.post(
          `${baseUrl}/api/create-chat/`,
          {}, 
          {
            headers: {
              Authorization: `Bearer ${token}`,  
            },
          }
        );

        if (response.data.success) {
          const newChatId = response.data.chat_id;
          const newChat = { chat_id: newChatId, title: "", messages: [] };
          setChats((prevChats) => [newChat, ...prevChats]);
          setCurrentChatId(newChatId);
          setIsNewChat(true); 
        }
      } else {
        // If the user is not authenticated, create a temporary chat
         response = await axios.post(
          `${baseUrl}/api/create-temp-chat/`,
          {
            user_id: userId,  // Assuming userId is generated/set for unauthenticated users
          }
        );

        if (response.data.success) {
          const newChatId = response.data.chat_id;
          const newChat = { chat_id: newChatId, title: "", messages: [] };
          setChats((prevChats) => [newChat, ...prevChats]);
          setCurrentChatId(newChatId);
          setIsNewChat(true); 
        }
      }
    } catch (error) {
      console.error("Error creating new chat:", error);
      setError("Failed to create a new chat. Please try again.");
    } finally {
      setLoading(false);
      isCreatingChat.current = false;
    }
  };


  // const toggleSidebar = () => {
  //   setSidebarVisible(!sidebarVisible);
  // };

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <div className="App">
            {showAgeConfirmation ? ( 
                <AgeConfirmation onConfirm={handleAgeConfirm} onCancel={handleAgeCancel} />
            ) :  limitReached ? ( // Show limit reached modal when prompt limit is hit
                <LimitReached onConfirm={handleLimitConfirm} />
            ) : (
                <>
                    <ChatHeader
                        onCreateNewChat={handleCreateNewChat}
                        chatHistory={chats} // Pass chat history
                        onSelectChat={handleSelectChat} // Pass chat selection handler
                        onLogout={handleLogout} // Pass logout handler
                        user={user} // Pass user details
                    />
      <div className="chat-container">
        <ChatWindow
          messages={
            chats.find((chat) => chat.chat_id === currentChatId)?.messages || []
          }
          onSendMessage={handleSendMessage}
          // userName={user?.name || "guest"}
          isNewChat={isNewChat} // Pass isNewChat to reset prompts
          resetNewChat={() => setIsNewChat(false)}
        />
      </div>
      {loading && <div className="loading-message"></div>}
      {error && <div className="error-message">{error}</div>}
      </>
    )}
    </div>
  );
};

export default AppContent;
// unauthorized ? ( // Show unauthorized message if user clicks "NO" on age confirmation
//   <div className="unauthorized-message">You are not authorized to view this content</div>
// ) :