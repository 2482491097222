import React from 'react';

const ChatHeader = () => {
    return (
        <div className="loading">
            <h1>Loading Bragr</h1>
        </div>
    );
};

export default ChatHeader;