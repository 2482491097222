import React from 'react';
import './AgeConfirmation.css';

const AgeConfirmation = ({ onConfirm, onCancel }) => {
    return (
        <div className="modal-age-overlay">
            <div className="modal-content custom-age-confirmation">
                <div className="header-section">
                    <div className="left-header">
                        <img src="BRAGR_ChatGPT_White.png" alt="BRAGR ChatGPT Logo" className="brand-logo" />
                        <p className="subtitle">Chat for sports fans who really care</p>
                    </div>
                    <div className="beta-container">
                        <img src="Rectangle Beta.png" alt="Rectangle Beta Background" className="rectangle-background" />
                        <img src="BETA.png" alt="Beta Label" className="beta-image" />
                    </div>
                </div>
                
                {/* Icons aligned with slight vertical offset */}
                <div className="icon-row">
                    <div className="icon-item top-icon">
                        <img src="Latest.png" alt="Latest News" />
                        <p>LATEST NEWS ON YOUR TEAM</p>
                    </div>
                    <div className="icon-item top-icon">
                        <img src="Recommend.png" alt="Recommended Picks" />
                        <p>RECOMMENDED PICKS ON TEAMS YOU WANT</p>
                    </div>
                    <div className="icon-item bottom-icon">
                        <img src="Ask.png" alt="Ask Anything" />
                        <p>ASK ANYTHING BRAGR ANSWERS</p>
                    </div>
                    <div className="icon-item bottom-icon">
                        <img src="Chat.png" alt="Sports Talk" />
                        <p>SPORTS TALK ANYTIME ANYWHERE</p>
                    </div>
                </div>
                
                <div className="note-section reduced-note">
                    <p className="note-title">PLEASE NOTE</p>
                    <p>Bragr ChatGPT is in beta - performance may be adversely affected by user volume. Please register to get the ultimate personalized ChatGPT.</p>
                    <p><span className="underline-text">We need you to confirm that you are over the age of legal consent in your country of residence.</span></p>
                    <div className="modal-buttons note-buttons">
                        <button className="yes-button" onClick={onConfirm}>YES</button>
                        <button className="no-button" onClick={onCancel}>NO</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgeConfirmation;
