import React from 'react';
import ReactMarkdown from 'react-markdown';

const ChatMessage = ({ message, role, }) => {
    return (
        <div className={`chat-message ${role}`}>
            <div className={`chat-message-content ${role}`}>
                <ReactMarkdown>{message}</ReactMarkdown>
            </div>
        </div>
    );
};

export default ChatMessage;
