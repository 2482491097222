// AuthWrapper.js

import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Routes, Route } from "react-router-dom";
import Loading from "./components/Loading";
import AppContent from "./AppContent";
const AuthWrapper = () => {
  const {isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  // if (!isAuthenticated) {
  //   loginWithRedirect();
  //   return <Loading />;
  // }

  return (
    <Routes>
      <Route path="/*" element={<AppContent />} />
    </Routes>
  );
};

export default AuthWrapper;
