import React, { useState, useEffect } from 'react';

const ChatInput = ({ onSendMessage, isNewChat, resetNewChat, hasExistingMessages, isLoading }) => {
    const [input, setInput] = useState('');
    const [showPrompts, setShowPrompts] = useState(!hasExistingMessages);

    // Predefined prompts
    const prompts = [
        "$$$: Find me a great bet today.",
        "Teams: Let's talk my favorite team. Ask mine.",
        "HOT: What's everyone else betting on?",
        "NFL: Gimme the strongest Parlays?",
        "Premier League: Best accumulators to win?",
        "College Football: Top Same Game Parlays?"
    ];

    useEffect(() => {
        if (isNewChat) {
            setShowPrompts(true); // Show prompts for a new chat
            resetNewChat(); // Reset the new chat state after handling
        }
    }, [isNewChat, resetNewChat]);

    useEffect(() => {
        setShowPrompts(!hasExistingMessages);
    }, [hasExistingMessages]);

    const handlePromptClick = (prompt) => {
        onSendMessage(prompt); // Send the prompt as a message
        setShowPrompts(false); // Hide prompts after sending
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (input.trim()) {
            onSendMessage(input);
            setInput('');  // Clear input field after sending
            setShowPrompts(false);  // Hide prompts after sending
        }
    };

    return (
        <div className="chat-input-container">
            {showPrompts && (
                <div className="chat-prompts">
                    {prompts.map((prompt, index) => (
                        <button 
                            key={index} 
                            className="chat-prompt-button" 
                            onClick={() => handlePromptClick(prompt)}>
                            {prompt}
                        </button>
                    ))}
                </div>
            )}

            <form className="chat-input-form" onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Chat Now"
                    className="chat-input-field"
                />
                {console.log("LOADINF", isLoading)}
                <button type="submit" className="chat-send-button" disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Chat'}
            </button>

            </form>
        </div>
    );
};

export default ChatInput;