import React, { useState, useEffect, useRef } from 'react';
import ContactForm from './ContactForm';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { useAuth0 } from "@auth0/auth0-react";
import {FaTrashAlt } from 'react-icons/fa'; 
import tosContent from './TermsOfService'; 
import privacyPolicyContent from './PrivacyPolicy'; 
import './ChatHeader.css';

const baseUrl = process.env.REACT_APP_BASE_URL;



const ChatHeader = ({ onCreateNewChat, chatHistory, onSelectChat, onLogout, user, currentChatId, setChats }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState(""); // Store TOS or Privacy Policy
    const [showContactForm, setShowContactForm] = useState(false); // State for Contact Form
    const { isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const [activeMenu, setActiveMenu] = useState(null);
    const [isClient, setIsClient] = useState(false);
  
    const modalRef = useRef(null); // Reference to modal content
    const dropdownRef = useRef(null);

    useEffect(() => {
        setIsClient(true);
      }, []);

    // Access token retrieval
  const fetchAccessToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      return token;
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };
  
    const toggleDropdown = () => {
      setDropdownVisible(!dropdownVisible);
    };
  
    const handleShowModalTOS = () => {
      setModalContent(tosContent);
      setShowModal(true);
      setDropdownVisible(false);
    };
  
    const handleShowModalPrivacy = () => {
      setModalContent(privacyPolicyContent);
      setShowModal(true);
      setDropdownVisible(false);
    };
  
    const handleShowContactForm = () => {
      setShowContactForm(true);
      setDropdownVisible(false);
    };
  
    const handleCloseContactForm = () => {
      setShowContactForm(false); // Close the contact form modal
    };
  
    const handleCloseModal = () => {
      setShowModal(false);
    };
  
  // Close dropdown if user clicks outside of it
  useEffect(() => {
    const handleClickOutsideDropdown = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false); // Close dropdown if click is outside
      }
    };

    if (dropdownVisible) {
      document.addEventListener('mousedown', handleClickOutsideDropdown);
    } else {
      document.removeEventListener('mousedown', handleClickOutsideDropdown);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropdown);
    };
  }, [dropdownVisible]);


    // Close modal if user clicks outside modal content
    // useEffect(() => {
    //   const handleClickOutside = (event) => {
    //     if (modalRef.current && !modalRef.current.contains(event.target)) {
    //       handleCloseModal();
    //     }
    //   };
  
    //   if (showModal) {
    //     document.addEventListener('mousedown', handleClickOutside);
    //   } else {
    //     document.removeEventListener('mousedown', handleClickOutside);
    //   }
  
    //   return () => {
    //     document.removeEventListener('mousedown', handleClickOutside);
    //   };
    // }, [showModal]);

    const handleGoToLogin = () => {
        loginWithRedirect();
      };
    
        // Function to handle deleting a chat
      const handleDeleteChat = async (chatId) => {
            try {
                const token = await fetchAccessToken();
                // Making the DELETE request to the Django backend
                const response = await axios.delete(`${baseUrl}/api/delete-chat/${chatId}/`, {
                headers: {
                  Authorization: `Bearer ${token}`,  
                },
                });
    
                // Checking the response status to confirm the chat was deleted
                if (response.status === 200) {
                    console.log("Chat deleted successfully");
                    setChats((prevChats) => prevChats.filter(chat => chat.chat_id !== chatId));
                    onSelectChat(null);
                } else {
                    console.error("Failed to delete chat: ", response.data.error);
                }
            } catch (error) {
                // Catch and display any error that occurs during the API request
                console.error("Error deleting chat:", error);
            } finally {
                // Optionally close any active menus related to chat actions
                setActiveMenu(null); 
            }
        };
    
    
      const calculateDropdownPosition = (chatId) => {
        if (typeof document !== 'undefined') {
          const buttonElement = document.querySelector(`#three-dots-button-${chatId}`);
          if (buttonElement) {
            const rect = buttonElement.getBoundingClientRect();
            return {
              position: 'absolute',
              top: `${rect.bottom}px`,
              left: `${rect.left - 0}px`, // Adjust for dropdown width
              zIndex: 1000,
            };
          }
        }
        return {};
      };    
  
      return (
        <div className="chat-header">
            <img
                src="MenuIcon.png"
                alt="Toggle Menu"
                className="toggle-sidebar-button"
                onClick={toggleDropdown}
            />
            <img 
                src="BRAGR_ChatGPT_White.png" 
                alt="Bragr AI Logo" 
                className="chat-header-logo"
            />
            <button className="new-chat-button" onClick={onCreateNewChat}>
                <img src="writenew.png" alt="New Chat" className="new-chat-icon" />
            </button>

            {dropdownVisible && (
  <div className="dropdown-menu" ref={dropdownRef}>
    <ul>
      <li className="chat-history-title">Chat History</li>
      <div className="chat-history-list">
        {chatHistory.map((chat, index) => (
          <li key={chat.chat_id} onClick={() => onSelectChat(chat.chat_id)}>
            {chat.title.trim() ? chat.title : 'New Chat'}
          </li>
        ))}
      </div>
      <div className="section-divider"></div>
      <li onClick={user ? () => console.log("Account Settings clicked") : null} 
          className={user ? "" : "disabled"}>
        Account Settings
      </li>
      <div className="section-divider"></div>
      <li onClick={handleShowModalTOS}>Terms of Use</li>
      <li onClick={handleShowModalPrivacy}>Privacy Policy</li>
      <li onClick={handleShowContactForm}>Contact Us</li>
      <div className="section-divider"></div>
      <li 
        onClick={user ? () => onLogout() : () => handleGoToLogin()} 
        className={user ? "" : ""}>
        {user ? "Log Out" : "Log In"}
      </li>
    </ul>
  </div>
)}



            {showModal && (
                <div className="terms-modal-overlay">
                    <div className="terms-modal-content" ref={modalRef}>
                        <button className="close-button" onClick={handleCloseModal}>X</button>
                        <div className="modal-text" dangerouslySetInnerHTML={{ __html: modalContent }} />
                    </div>
                </div>
            )}

            {showContactForm && (
                <div className="terms-modal-overlay">
                    <div className="terms-modal-content" ref={modalRef}>
                        <button className="close-button" onClick={handleCloseContactForm}>X</button>
                        <ContactForm onClose={handleCloseContactForm} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChatHeader;