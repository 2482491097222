import React, { useEffect, useRef, useState } from 'react';
// import React, { useEffect, useRef, useState } from 'react';
import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';

const ChatWindow = ({ messages, onSendMessage, isNewChat, resetNewChat }) => {
    const messagesEndRef = useRef(null); 
    const [isLoading, setIsLoading] = useState(false); 
    const [loadingText, setLoadingText] = useState('Thinking...'); 
    const [processedMessages, setProcessedMessages] = useState(messages); 

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [processedMessages]);

    const handleSendMessage = async (message) => {
        if (isLoading) return; // Prevent multiple submissions during loading

        setIsLoading(true); // Set loading state
        setLoadingText('Gathering my thoughts...'); // Initial loading text

        const userMessage = { role: 'user', content: message };
        setProcessedMessages(prevMessages => [...prevMessages, userMessage]);

        // Loading text stages
        const loadingStages = ['Scanning sources for information...','Sifting through the details...','Bringing the pieces together...','Ensuring everything is accurate...','Putting on the finishing touches...','Wrapping things up for you...'];
        let stageIndex = 0;

        const loadingInterval = setInterval(() => {
            if (stageIndex < loadingStages.length) {
                setLoadingText(loadingStages[stageIndex]);
                stageIndex++;
            }
        }, 2800); // Change every 2 seconds

        // Send the message and wait for the response
        await onSendMessage(message);

        // Stop the loading animation and show the answer
        clearInterval(loadingInterval);
        setLoadingText('Here is your answer');
        setIsLoading(false); // Reset loading state
    };

    useEffect(() => {
        setProcessedMessages(messages);
    }, [messages]);

    return (
        <div className="chat-window">
            <div className="messages">
                {/* Display processed messages */}
                {processedMessages.map((msg, index) => (
                    <div key={index}>
                        <ChatMessage message={msg.content} role={msg.role} />
                    </div>
                ))}

                {isLoading && (
                    <div className="loading-container">
                        <img className="rotating-ball" src="BragrBall.png" alt="Loading..." />
                        <span className="loading-text">{loadingText}</span>
                    </div>
                )}

                <div ref={messagesEndRef} />
            </div>

            <ChatInput 
                onSendMessage={handleSendMessage} 
                isNewChat={isNewChat} 
                resetNewChat={resetNewChat} 
                isLoading = {isLoading}
                hasExistingMessages={processedMessages.length > 0}
            />
        </div>
    );
};

export default ChatWindow;
