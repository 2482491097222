// App.js

import React from "react";
import { Routes, Route } from "react-router-dom";
import AuthWrapper from "./AuthWrapper";
import "./App.css";
import DeploymentChecker from './DeploymentChecker';

const App = () => {
  return (
    <>
    <DeploymentChecker />
    <Routes>
      <Route path="/*" element={<AuthWrapper />} />
    </Routes>
    </>
  );
};

export default App;
